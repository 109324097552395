@keyframes slides {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.carousel-wrapper {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  display: flex;


}

.carousel-track {
  animation: 40s slides infinite linear;
  display: flex;
  @apply pl-10;
  @apply md:pl-20;
  @apply gap-10;
  @apply md:gap-20;
}