$transform-origin-small: 20px;
$transform-origin-medium: 35px;
$transform-origin-large: 50px;

.rotating-text {
  .cube-container {
    animation-name: cubeAnimation;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 14s;
    transform-style: preserve-3d;
    transform-origin: -50% $transform-origin-small;
    position: absolute;
    inset: 0;

    div {
      position: absolute;
      inset: 0;
      @media (min-width: 1024px) {
        max-width: -moz-max-content;
        max-width: max-content;
      }
    }

    @media (min-width: 768px) {
      transform-origin: $transform-origin-medium $transform-origin-medium 0;
    }
    @media (min-width: 1024px) {
      display: inline;
      transform-origin: $transform-origin-large $transform-origin-large 0;
      left: 0;
      top: 8px;
    }

    @media (min-width: 1280px) {
      animation-delay: 8s;
    }

    .cube-face-1 {
      transform: rotateX(0deg) translateZ($transform-origin-small);
      -webkit-backface-visibility: hidden;
      @media (min-width: 1024px) {
        transform: rotateX(0deg) translateZ($transform-origin-large);
      }

      @media (min-width: 768px) {
        transform: rotateX(0deg) translateZ($transform-origin-medium);
      }
    }

    .cube-face-2 {
      transform: rotateX(90deg) translateZ($transform-origin-small);
      -webkit-backface-visibility: hidden;
      @media (min-width: 1024px) {
        transform: rotateX(90deg) translateZ($transform-origin-large);
      }

      @media (min-width: 768px) {
        transform: rotateX(90deg) translateZ($transform-origin-medium);
      }
    }

    .cube-face-3 {
      transform: rotateX(180deg) translateZ($transform-origin-small);
      -webkit-backface-visibility: hidden;

      @media (min-width: 1024px) {
        transform: rotateX(180deg) translateZ($transform-origin-large);
      }

      @media (min-width: 768px) {
        transform: rotateX(180deg) translateZ($transform-origin-medium);
      }
    }

    .cube-face-4 {
      transform: rotateX(270deg) translateZ($transform-origin-small);
      -webkit-backface-visibility: hidden;
      @media (min-width: 1024px) {
        transform: rotateX(270deg) translateZ($transform-origin-large);
      }

      @media (min-width: 768px) {
        transform: rotateX(270deg) translateZ($transform-origin-medium);
      }
    }
  }
}

@keyframes cubeAnimation {
  0%,
  100% {
    transform: rotateX(0deg);
  }

  0%,
  20% {
    transform: rotateX(0deg);
  }
  25%,
  45% {
    transform: rotateX(90deg);
  }
  50%,
  70% {
    transform: rotateX(180deg);
  }
  75%,
  95% {
    transform: rotateX(270deg);
  }
  100% {
    transform: rotateX(1turn);
  }
}
